import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Section,
  Wrapper,
  ButtonLink,
  ImageContent,
  ProjectImageWrapper,
  ProjectImageWrapperLink,
  ProjectImage,
  ProjectText,
  ProjectHeader,
  ImageIcons,
  ProjectDescription,
  NoContent,
  SkillIcon,
  TechnologiesList,
  TypesOfDesignIconWrapper,
  TypesOfDesignTitle,
  Platforms,
} from './styles'
import { ButtonWrapper } from '../../UI/button'
import SEO from '../../Seo'

class CustomSingleProjects extends Component {
  state = {
    technologies: [],
  }

  componentDidMount() {
    const technologiesArray = this.props.data.technologies
    const technologies = [
      { title: 'web' },
      { title: 'mobile' },
      { title: 'design' },
    ]

    technologiesArray.map((technology) => {
      switch (technology) {
        case 'web':
          technologies[0].icon = 'ikona_web.svg'
          break
        case 'mobile':
          technologies[1].icon = 'ikona_mobile.svg'
          break
        case 'design':
          technologies[2].icon = 'ikona_design.svg'
          break
        default:
          break
      }
      return technologies
    })

    this.setState({
      technologies: technologies,
    })
  }

  tryRequire = (path) => {
    try {
      return require(`../../../images/projects/technologies/${path}.svg`)
    } catch (err) {
      return null
    }
  }

  render() {
    const { data, html } = this.props
    const usedTechnologies = data.usedTechnologies.map((technology, index) => {
      let lowercaseTechnology = technology.toLowerCase().replace(/\s/g, '')
      let image = this.tryRequire(lowercaseTechnology)
      let skillIcons
      if (image) {
        skillIcons = (
          <SkillIcon
            key={index}
            src={image}
            alt={`${technology} technology`}
            title={technology}
          />
        )
      }
      return skillIcons
    })

    let typesOfDesign = this.state.technologies.map((type) => {
      let typesOfDesignIcons = ''
      if (type.icon) {
        typesOfDesignIcons = (
          <TypesOfDesignIconWrapper key={type.icon}>
            <img
              alt={type.title + ' platform'}
              title={type.title + ' platform'}
              src={require(`../../../images/projects/${type.icon}`)}
            />
            <TypesOfDesignTitle>{type.title}</TypesOfDesignTitle>
          </TypesOfDesignIconWrapper>
        )
      }
      return typesOfDesignIcons
    })

    if (data) {
      let link = data.link
      return (
        <Section>
          <SEO title={data.title}/>
          <Wrapper>
            <ImageContent>
              {link ? (
                <ProjectImageWrapperLink href={link} target={'_BLANK'}>
                  <picture>
                    <source
                      srcSet={require(`../../../images/projects/${data.imageName}.webp`)}
                      type="image/webp"
                    />
                    <source
                      srcSet={require(`../../../images/projects/${data.imageName}.png`)}
                      type="image/jpeg"
                    />
                    <ProjectImage
                      src={require(`../../../images/projects/${data.imageName}.png`)}
                      alt={data.title + ' project'}
                      title={data.title + ' project'}
                    />
                  </picture>
                </ProjectImageWrapperLink>
              ) : (
                <ProjectImageWrapper>
                  <picture>
                    <source
                      srcSet={require(`../../../images/projects/${data.imageName}.webp`)}
                      type="image/webp"
                    />
                    <source
                      srcSet={require(`../../../images/projects/${data.imageName}.png`)}
                      type="image/jpeg"
                    />
                    <ProjectImage
                      src={require(`../../../images/projects/${data.imageName}.png`)}
                      alt={data.title + ' project image'}
                    />
                  </picture>
                </ProjectImageWrapper>
              )}
            </ImageContent>
            <ProjectText>
              <ProjectHeader>{data.title}</ProjectHeader>
              <ProjectDescription dangerouslySetInnerHTML={{ __html: html }} />
              <ProjectHeader>Used technologies:</ProjectHeader>
              <TechnologiesList>{usedTechnologies}</TechnologiesList>
              <Platforms>
                <ProjectHeader>Platforms:</ProjectHeader>
                <ImageIcons>{typesOfDesign}</ImageIcons>
              </Platforms>
            </ProjectText>
          </Wrapper>
          <ButtonWrapper paddingTop={'50px'} paddingBottom={'50px'}>
            <ButtonLink to={'/portfolio'}> Back to other projects </ButtonLink>
          </ButtonWrapper>
        </Section>
      )
    } else {
      return (
        <Section>
          <NoContent>
            <p>{this.props.information}</p>
          </NoContent>
        </Section>
      )
    }
  }
}

CustomSingleProjects.propTypes = {
  data: PropTypes.shape({
    imageName: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    technologies: PropTypes.array,
  }),
}

CustomSingleProjects.defaultProps = {
  information: 'No posts',
}

export default CustomSingleProjects
