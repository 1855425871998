import styled from 'styled-components'
import {
  fullhd,
  largeBreakpoint,
  mediumBreakpoint,
  xlargeBreakpoint,
} from '../../../config/css-constants'
import { fadeFromLeft } from '../../../assets/keyframes/keyframes'
import { Link } from 'gatsby'
import React from 'react'

export const Section = styled.section`
  height: 100%;
  @media all and (min-width: ${mediumBreakpoint}) {
    padding-bottom: 50px;
  }
`

export const Wrapper = styled.div`
  display: grid;
  grid-gap: 50px;
  justify-content: center;
  align-items: center;
  @media all and (min-width: ${largeBreakpoint}) {
    grid-template-columns: 1fr 1fr;
    min-height: 500px;
  }
`

export const ImageContent = styled.div`
  width: 100%;
  height: 100%;
`

export const ProjectImageWrapperLink = styled.a`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #357e8d;
  padding: 70% 0 0;
  @media all and (min-width: ${mediumBreakpoint}) {
    padding: 100px 50px;
  }
  @media all and (min-width: ${xlargeBreakpoint}) {
    order: unset;
    margin-top: 0;
    height: 100%;
  }
`

export const ProjectImageWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #357e8d;
  padding: 70% 0 0;
  @media all and (min-width: ${mediumBreakpoint}) {
    padding: 100px 50px;
  }
  @media all and (min-width: ${xlargeBreakpoint}) {
    order: unset;
    margin-top: 0;
    height: 100%;
  }
`

export const ProjectImage = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 300px;
  @media all and (min-width: ${mediumBreakpoint}) {
    position: static;
    transform: translateX(-100%);
    animation-fill-mode: forwards;
    animation-name: ${fadeFromLeft};
    animation-duration: 1.5s;
    animation-timing-function: ease-in;
  }
  @media all and (min-width: ${fullhd}) {
    min-height: 300px;
  }
`

export const ImageIcons = styled.div`
  width: 100%;
  text-align: center;
  @media all and (min-width: ${mediumBreakpoint}) {
    min-height: 80px;
  }
  @media all and (min-width: ${largeBreakpoint}) {
    text-align: unset;
  }
  img {
    width: 60px;
    height: 60px;
    margin: 3px;
    @media all and (min-width: ${largeBreakpoint}) {
      width: 80px;
      height: 80px;
    }
  }
`

export const ProjectText = styled.div`
  padding: 0 30px;
  order: 1;
  @media all and (min-width: ${largeBreakpoint}) {
    max-width: 95%;
    padding: 30px 0 0;
  }
  @media all and (min-width: ${fullhd}) {
    max-width: 80%;
  }
`

export const ProjectHeader = styled.h2`
  margin-bottom: 20px;
  font-weight: bold;
  text-align: center;
  @media all and (min-width: ${largeBreakpoint}) {
    text-align: left;
    font-size: 1.3rem;
    text-transform: uppercase;
    line-height: 2rem;
    letter-spacing: 2px;
  }
`

export const ProjectDescription = styled.div`
  margin-bottom: 2rem;
  text-align: center;
  @media all and (min-width: ${largeBreakpoint}) {
    text-align: left;
    font-size: 1rem;
    font-weight: 400;
    line-height: 2rem;
  }
  p {
    margin-bottom: 1rem;
  }
`

export const NoContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
  text-align: center;
  font-size: 1.6rem;
`

export const TechnologiesList = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
  justify-content: center;
  @media all and (min-width: ${largeBreakpoint}) {
    justify-content: unset;
    text-align: left;
    padding-bottom: 30px;
  }
`

export const SkillIcon = styled.img`
  width: auto;
  max-height: 60px;
  filter: grayscale(100%);
  opacity: 0.8;
  margin: 5px 15px;
  @media all and (min-width: ${mediumBreakpoint}) {
    padding: 0;
    margin-right: 30px;
    margin-bottom: 10px;
  }
`

export const TypesOfDesignIconWrapper = styled.div`
  display: inline-block;
  margin-right: 15px;
  text-align: center;
  @media all and (min-width: ${mediumBreakpoint}) {
    margin-right: 25px;
  }
`

export const TypesOfDesignTitle = styled.p`
  color: #357e8d;
`

export const ButtonLink = styled((props) => <Link {...props} />)`
  background-color: #357e8d;
  border: 2px solid transparent;
  color: #fff;
  padding: 10px 30px;
  border-radius: 15px;
  display: inline-block;
  margin-bottom: 30px;
  transition: all 0.4s ease;
  min-width: 150px;
  position: relative;
  @media all and (min-width: ${mediumBreakpoint}) {
    margin-top: 30px;
    width: 240px;
  }
  &:hover {
    color: #357e8d;
    border: 2px solid #357e8d;
    background-color: unset;
  }
`

export const Platforms = styled.div`
  display: none;
  @media all and (min-width: ${xlargeBreakpoint}) {
    display: block;
  }
`
