import React from 'react'
import { graphql } from 'gatsby'
import CustomSingleProject from '../components/CustomProjects/CustomSingleProject/CustomSingleProject'

const Template = ({ data }) => {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  return <CustomSingleProject data={frontmatter} html={html} />
}

export const query = graphql`
  query ($pathSlug: String!) {
    markdownRemark(frontmatter: { path: { eq: $pathSlug } }) {
      html
      frontmatter {
        title
        imageName
        description
        technologies
        link
        usedTechnologies
      }
    }
  }
`

export default Template
